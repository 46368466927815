.PersistBtn {
    width: 90%;
    background-color:  #8639F1;
    border-color:  #8639F1;
}

.PersistBtn:hover {
    width: 90%;
    background-color:  #8639F1;
    border-color:  #8639F1;
}

.PersistBtn:disabled {
    width: 90%;
    background-color:  #ede1fd;
    border-color:  #ede1fd;
}

.EmojiDropdown:active {
    background-color:  #ffffff;
}

.dropdown-item:hover {
    background-color:  #ffffff;
}

.accordion .wall {
    overflow: inherit;
}

.modal-xl {
    max-width: 95%;
}

.accordion-button {
    color: #8639F1 !important;
    background-color: #ede1fd !important
}