.contribution {
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 5px;
    word-wrap: break-word;
}

.Homelinks {
    color: #8639F1 !important;
}

.Homelinks:Hover {
    color: #8639F1 !important;
}

.react-player > div {
    position: absolute;
}