

.wallBreadcrumb > a {color: #FFFFFF !important;
                    text-decoration: none}
a:hover {color: #FFFFFF}

.breadcrumb { background-color:  #8639F1 !important; border-radius: 5px; min-height: 35px; text-align:center; box-shadow: 0px 2px 5px -1px #333;}

.breadcrumb-item.active {
    color: #FFFFFF;
    font-weight: bold;
}

.breadcrumb-item+.breadcrumb-item::before {
    color: #FFFFFF;
    content: ">";
}

.breadcrumb-item{
    margin-top: 5px;
    margin-left: 5px;
}

