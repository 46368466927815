.DeliveryCreate .form-control:valid {
    border-color: rgba(34, 36, 38, 0.15) !important;
    background-image: none !important;
    padding-right: 0.75rem !important;
}

.DeliveryCreate .react-multi-email {
    border-color: rgba(34, 36, 38, 0.15) !important;
}

.premiumToggle > input:checked {
    background-color: #8639F1 !important;
    border-color: #8639F1 !important;
}

.premiumToggle > input {
    background-color: #FFFFFF !important;
    border-color: #ced4da !important;
    box-shadow: 0px 2px 5px -1px #333;
}

.premiumToggle > label {
    color: #212529 !important;
}