.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.WallsDropdown .btn-success{
    background-color: #FFFFFF !important;
    border-color: #FFFFFF !important;
    color:  #8639F1 !important;
}

.WallsDropdown .btn-success:hover{
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    color:  #8639F1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css?family=Shizuru');
@import url('https://fonts.googleapis.com/css?family=Redressed');
@import url('https://fonts.googleapis.com/css?family=Festive');
@import url('https://fonts.googleapis.com/css?family=Akaya+Telivigala');
@import url('https://fonts.googleapis.com/css?family=Lobster');
@import url('https://fonts.googleapis.com/css?family=Dancing+Script');
@import url('https://fonts.googleapis.com/css?family=Pacifico');
@import url('https://fonts.googleapis.com/css?family=Shadows+Into+Light');
@import url('https://fonts.googleapis.com/css?family=Indie+Flower');
@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
@import url('https://fonts.googleapis.com/css?family=Caveat');
@import url('https://fonts.googleapis.com/css?family=Satisfy');
@import url('https://fonts.googleapis.com/css?family=Cookie');
@import url('https://fonts.googleapis.com/css?family=Courgette');
@import url('https://fonts.googleapis.com/css?family=Great+Vibes');
@import url('https://fonts.googleapis.com/css?family=Parisienne');
@import url('https://fonts.googleapis.com/css?family=Sacramento');
@import url('https://fonts.googleapis.com/css?family=Gloria+Hallelujah');
@import url('https://fonts.googleapis.com/css?family=Charm');
@import url('https://fonts.googleapis.com/css?family=Tangerine');
@import url('https://fonts.googleapis.com/css?family=Allura');
@import url('https://fonts.googleapis.com/css?family=Homemade+Apple');
@import url('https://fonts.googleapis.com/css?family=Rock+Salt');
@import url('https://fonts.googleapis.com/css?family=Bad+Script');
@import url('https://fonts.googleapis.com/css?family=Alex+Brush');
@import url('https://fonts.googleapis.com/css?family=Nothing+You+Could+Do');
@import url('https://fonts.googleapis.com/css?family=Love+Light');
@import url('https://fonts.googleapis.com/css?family=Ole');
@import url('https://fonts.googleapis.com/css?family=Italianno');
