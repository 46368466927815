

.checkboxes { margin-bottom: 0;}


.text-radio-label { width: 50%; float: none; margin-bottom: 0;}
.text-radio-input {
    width: 15%;
    float: left;
    border: 1px solid #000;
    height: 14px;
    margin-top: 3px;
    margin-bottom: 2px;
    margin-left: 2px;
    vertical-align: middle;}
.designwall text-radio-span {
    width: 60%;
    float: left;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: 2px;
    vertical-align: middle;}

textarea {
    outline: none !important;
    border-color: #FFFFFF;
}
form {
    margin-top: 0.5em
}

.dropdown-toggle {
    float: right;
    background-color:  #8639F1 !important;
    border-color:  #8639F1 !important;
    width: 100%
}

.WowPatternLink {
    color: #8639F1 !important;
}

.WowPatternLink:Hover {
    color: #8639F1 !important;
}